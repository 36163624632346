<template>
  <section class="invoice-preview-wrapper">
    <div class="row">
      <!-- First column-->
      <div class="col-12 col-lg-9">
        <section id="print">
          <Invoice
            :commandeInfo="commandeInfo"
            :billingInfo="billingInfo"
            :shippingInfo="shippingInfo"
            :productInfo="productInfo"
          />
        </section>
      </div>
      <div class="col-12 col-lg-3">
        <div class="card mb-4">
          <div class="card-header"></div>
          <div class="card-body">
            <b-button
              type="submit"
              class="btn mb-2"
              style="width: -moz-available"
              variant="outline-primary"
              @click="downloadPDF()"
            >
              Télécharger la facture
            </b-button>
            <b-button
              type="submit"
              class="btn mb-2"
              style="width: -moz-available"
              variant="outline-primary"
              @click="RedirectToEditCommande(commandeInfo.id)"
            >
              Modifier la commande
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import html2pdf from "html2pdf.js";
import axios from "axios";
import websiteURL from "@/websiteURL";
import { BTable, BButton } from "bootstrap-vue";
import logoMercarue from "../../assets/images/logo/logoMercarue.svg";
import SettingsCommandes from "./SettingsCommandes";
import Invoice from "./Invoice.vue";
export default {
  components: {
    BTable,
    BButton,
    Invoice,
  },
  data() {
    return {
      commandeInfo: {},
      billingInfo: {},
      productInfo: [],
      shippingInfo: {},
      statusValue: {},
      logoMercarue: logoMercarue,
      status: [
        { value: "pending", text: "Attente paiement" },
        { value: "processing", text: "En cours" },
        { value: "on-hold", text: "En attente" },
        { value: "completed", text: "Terminée" },
        { value: "cancelled", text: "Annulée" },
        { value: "refunded", text: "Remboursée" },
        { value: "failed", text: "Échouée" },
      ],
    };
  },
  setup() {
    const { resolveCommandesStatusVariantAndIcon } = SettingsCommandes();

    return {
      resolveCommandesStatusVariantAndIcon,
    };
  },
  async beforeRouteEnter(to, from, next) {
    const apiUrl = websiteURL.WEBSITE + `wp-json/wc/v3/orders/${to.params.id}`;
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;
    try {
      const response = await axios.get(apiUrl, {
        auth: {
          username: consumerKey,
          password: consumerSecret,
        },
      });
      next((vm) => {
        vm.commandeInfo = response.data;
        (vm.commandeInfo.date_created = vm.commandeInfo.date_created.slice(
          0,
          10
        )),
          (vm.commandeInfo.status = vm.resolveCommandesStatusVariantAndIcon(
            vm.commandeInfo.status
          ).text),
          (vm.billingInfo = vm.commandeInfo.billing);
        vm.shippingInfo = vm.commandeInfo.shipping;
        vm.productInfo = vm.commandeInfo.line_items;
      });
    } catch (error) {
      alert("Une erreur s'est produite, veuillez réessayer plus tard", error);
      next(null);
    }
  },

  methods: {
    downloadPDF() {
      const content = document.getElementById("print");

      html2pdf(content);
    },

    async RedirectToEditCommande(item) {
      this.$router.push({
        name: "Edit Commande",
        params: { id: item },
      });
    },
  },
};
</script>
